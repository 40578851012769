'use client';

import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from 'react';

export const VideoPresentation = () => {

    const [showVideo, setShowVideo] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowVideo(true);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <div
                id="intro"
                className={`absolute inset-0 w-full h-full bg-black ${showVideo ? "opacity-0" : "opacity-100"} transition-opacity duration-2000`}
                style={{
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    backgroundBlendMode: "darken",
                }}
            />
            <AnimatePresence>
                {showVideo && (
                    <>
                        <motion.video
                            transition={{ duration: 16 }}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            autoPlay
                            loop
                            muted
                            playsInline
                            aria-label="Video de fondo mostrando servicios"
                            preload="none"
                            className={`absolute top-0 inset-0 w-full h-full object-cover transition-opacity duration-3000 ${showVideo ? "opacity-100" : "opacity-0"}`}
                            src="/images/videos/bgVideoHome.mp4"
                            role="img"
                            aria-describedby="video-description"
                        />
                        <div id="video-description" className="sr-only">
                            Video de fondo mostrando servicios de la empresa.
                        </div>
                    </>
                )}
            </AnimatePresence>
        </>
    )
};

export default VideoPresentation;