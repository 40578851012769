'use client';
import React, { useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'swiper/css/autoplay';

import Link from "next/link";

interface Item {
  title: string;
  bg: string;
  description: string;
  url: string;
  image: string;
}
import { FreeMode, Navigation, Thumbs, Autoplay } from 'swiper/modules';
import Image from 'next/image';

export default function Carousel({ items }: { items: any[] }) {

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  if (items.length === 0) return null;
  return (
    items.length > 0 && (
      <div className="carousel" style={{ margin: '0 auto', padding: 0 }} aria-labelledby="carousel-heading">
        <header className="sr-only">
          <h2 id="carousel-heading">Carrusel de Artículos</h2>
        </header>
        <Swiper
          style={{
            '--swiper-navigation-color': '#fff',
            '--swiper-pagination-color': '#fff',
            height: '400px'
          } as any}
          loop={true}
          speed={5000}
          autoplay={{
            delay: 1000,
            disableOnInteraction: false
          }}
          spaceBetween={10}
          navigation={false}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Navigation, Thumbs, Autoplay]}
          className="mySwiper2"
        >
          {items?.map((item, index) => (
            <SwiperSlide key={`sub-1-${item.title}-${index}`}>
              <Link href={item?.url || '#'} aria-label={`Leer más sobre ${item.title}`}>
                <div className="item bg-contain" style={{ backgroundImage: item.bg, borderRadius: '0', height: '400px' }}>
                  <div className="item-desc">
                    <h3 className="font-semibold text-start text-xl mb-2">{item.title}</h3>
                    <p className="font-light text-start">{item.description}</p>
                  </div>
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
        <Swiper
          style={{
            height: '160px',
          }}
          onSwiper={setThumbsSwiper as any}
          loop={true}
          speed={5000}
          spaceBetween={10}
          slidesPerView={4}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper"
        >
          {items?.map((article, index) => (
            <SwiperSlide key={`sub-2-${article.title}-${index}`}>
              <Image height={'100'} width={'100'} alt={article.title} loading="lazy" src={article?.image || article.imgSlider || ''} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    )
  );
}
