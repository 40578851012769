'use client'

import { GoogleTagManager } from "@next/third-parties/google"
import { GoogleAnalytics as GoogleAnalyticsInstance } from "@next/third-parties/google"
import { usePathname } from 'next/navigation';
import React, { useEffect } from "react";

export default function GoogleAnalytics() {
    const pathname = usePathname();

    useEffect(() => {
        const handleEventNavigation = () => {
            if (window.gtag) {
                window.gtag('config', String(process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID), {
                    page_path: window.location.pathname,
                });
            }
        };


        return () => {
            handleEventNavigation();
        }


    }, [pathname]);

    return (
        <>
            <GoogleAnalyticsInstance gaId={String(process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID)} />
            <GoogleTagManager gtmId="GTM-XYZ" />
        </>
    )
}