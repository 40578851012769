'use client';

import { motion, useScroll, useSpring } from "framer-motion";
import styles from './index.module.scss';

export const ProgressBar = () => {
    const { scrollYProgress } = useScroll();
    const scaleX = useSpring(scrollYProgress, {
        stiffness: 100,
        damping: 30,
        restDelta: 0.001
    });

    return (
        <div className={styles['progress-container']}>
            <motion.div
                className={styles['progress-bar']}
                style={{ scaleX }}
                role="progressbar"
                aria-label="Barra de progreso"
                aria-valuemin={0}
                aria-valuemax={1}
                aria-valuenow={scaleX.get()}
            ></motion.div>
        </div>
    );
};

export default ProgressBar;
