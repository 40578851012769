'use client';
import styles from './index.module.scss';
import { useEffect, useState } from 'react';
export const ScrollTopButton = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const backToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

    useEffect(() => {
        const checkScroll = () => {
            if (window.scrollY > 200) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', checkScroll);

        return () => {
            window.removeEventListener('scroll', checkScroll);
        };
    }, [isScrolled]);
    return (
        <div className={styles.container} id="content">
            <button
                className={`${styles['back-to-top']} ${isScrolled ? styles.show : ''} fa-solid fa-chevron-up`}
                type="button"
                onClick={backToTop}
                aria-label="Volver al inicio"
                title="Volver al inicio"
            ></button>
        </div>
    )
};

export default ScrollTopButton;