'use client';
import React, { createRef, useEffect, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Autoplay } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

import styles from './index.module.scss';
import { useRouter } from 'next/navigation';

interface Item {
    title: string;
    bg: string;
    description: string;
    url: string;
    imgSlider: string;
}

export default function CarouselSwiper({ items }: { items: Item[] }) {
    const SPEED = 3000;
    const router = useRouter();
    const refPaginations = useRef<React.RefObject<HTMLDivElement>[]>([]);

    useEffect(() => {
        if (refPaginations.current) {
            const slides = refPaginations.current.filter(item => item.current);
            if (slides.length > 0) {
                slides[0].current?.classList.add(styles['active']);
            }
        }
    }, []);
    return (
        <div data-aos="fade-up" data-aos-delay="200" aria-labelledby="carousel-heading">
            <header className="sr-only">
                <h2 id="carousel-heading">Carrusel de Artículos</h2>
            </header>
            <Swiper
                className='swiper-blog'
                spaceBetween={20}
                grabCursor={true}
                slidesPerView={1}
                breakpoints={{
                    600: {
                        slidesPerView: 2.5,
                    },
                    1440: {
                        slidesPerView: 3,
                    }
                }}
                speed={SPEED}
                loop={true}
                autoplay={{
                    delay: 10,
                    disableOnInteraction: false
                }}
                pagination={true}
                modules={[EffectCoverflow, Autoplay]}
                onSlideChange={({ realIndex }) => {
                    const index = realIndex > 4 ? 0 : realIndex;
                    refPaginations.current
                        .filter(item => item.current)
                        .forEach((ref, i) => {
                            if (ref.current) {
                                ref.current.classList.remove(styles['active']);
                            }
                            if (i === index && ref.current) {
                                ref.current.classList.add(styles['active']);
                            }
                        });
                }}
            >
                {items.map((article, index) => (
                    <SwiperSlide key={'sub-carousel-1-' + Math.random()} className={styles['swiper-slide']}>
                        {({ isActive }) => (
                            <div
                                onClick={() => {
                                    if (isActive) {
                                        router.push(article.url);
                                    }
                                }}
                                className={`h-full flex flex-col justify-end ${isActive ? 'cursor-pointer' : ''}`}
                                style={{ backgroundImage: article.bg, backgroundSize: 'cover' }}
                                role="button"
                                aria-label={`Ver artículo: ${article.title}`}
                            >
                                <div className='p-5 text-white w-full top-auto text-left'>
                                    <p className={`text-xl font-semibold pb-2`}>
                                        {article.title}
                                    </p>
                                    <p className={`font-light pb-0`}>
                                        {article.description}
                                    </p>
                                </div>
                            </div>
                        )}
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className={styles['c-carousel-pagination']} role="navigation" aria-label="Paginación del carrusel">
                {Array.from({ length: items.length - 1 }).map((_, index) => {
                    const element = createRef<HTMLDivElement>();
                    refPaginations.current.push(element);
                    return (
                        <div key={'sub-carousel-2-' + index} className={`${styles['c-carousel-pagination__item']} relative h-[5px] w-[200px] bg-gray-300`}>
                            <div
                                ref={element}
                                className={`absolute h-full top-0 bg-black ${styles['c-carousel-pagination__bullet']}`}
                                aria-hidden="true"
                            ></div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
